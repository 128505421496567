import { useState } from 'react'
import birds from '../data/birds.json'

const randomizedBirds = birds
  .sort(() => Math.random() - 0.5)
  .map((bird) => {
    let link = bird.link
    link = link.replace('/commons/', '/commons/thumb/')
    const lastPart = link.split('/').pop()
    link = link + '/1024px-' + lastPart
    return { ...bird, link }
  })

export const BirdGenerator = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  console.log(randomizedBirds)

  const nextBird = () => {
    setCurrentIndex((currentIndex + 1) % randomizedBirds.length)
  }

  const bird = randomizedBirds[currentIndex]

  return (
    <div className="flex flex-col justify-center items-center gap-4 py-4">
      <h1>{bird.Nimi}</h1>
      <h4>{bird['Tietellinen nimi ja järjestys']}</h4>
      <a href={'https://fi.wikipedia.org/wiki/' + bird.Nimi} target="_blank" rel="noreferrer">
        Wikipedia
      </a>
      <button onClick={() => nextBird()} className="border-black border-2 p-2 rounded-md">
        New Random Bird
      </button>
      <img
        src={bird.link}
        alt={bird.Nimi}
        className="h-[70vh] object-contain"
        style={{ objectPosition: '50% 0' }}
      ></img>
    </div>
  )
}
