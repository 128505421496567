import { ProjectFields } from '../types'

const projects: ProjectFields[] = [
  {
    name: 'Viinamäyräkoira',
    shortDescription: ` is a website for comparing alcohol prices (and much more)
    in Finnish stores.`,
    tags: 'React, NodeJS, GraphQl, Docker, Bootstrap',
    picture: 'viinamayrakoira.png',
    link: 'https://viinamayrakoira.otju.dev',
    sourceCodeLink: 'https://github.com/Otju/viinamayrakoira',
    bulletPoints: [
      'Uses React, Bootstrap, GraphQL, and MongoDB',
      'Runs in Google Cloud using a Docker container',
      'Features: user authentication, commenting, search with filtering and sorting, and much more',
    ],
  },
  {
    name: `Aalto Gamers website`,
    shortDescription: ` was completely overhauled by me. I also added a CMS for easy content management and signup system for events.`,
    tags: 'React, Next.js, NodeJS, Typescript, TailwindCSS, Decap CMS, Firebase',
    picture: 'aaltogamers.png',
    link: 'https://aaltogamers.fi',
    sourceCodeLink: 'https://github.com/aaltogamers/AG_web',
    bulletPoints: [
      'Uses React, Next.js, TailwindCSS and Decap CMS',
      'Features: CMS, Integrated admin panel and event signup system made using Google FireBase',
    ],
  },
  {
    name: 'Karaoke!',
    shortDescription: ` is for singing karaoke SingStar style, in your browser. Never fully finished, but still works.`,
    tags: 'React, NodeJS, Docker, Typescript',
    picture: 'karaoke.png',
    link: 'https://karaoke.otju.dev',
    sourceCodeLink: 'https://github.com/Otju/karaoke',
    bulletPoints: [
      'Uses React, TypeScript, Apollo, GraphQL, and MongoDB',
      'Features: real-time note detection and lyric-synced video playback',
    ],
  },
  {
    name: 'Asunnonetsijä',
    shortDescription: ` is a simple website for finding the perfect apartment in the Helsinki metropolitan area. This old project is not really functional anymore, as all the data is outdated.`,
    tags: 'React, NodeJS, Typescript',
    picture: 'asunnonetsija.png',
    link: 'https://asunnonetsija.otju.dev',
    sourceCodeLink: 'https://github.com/Otju/asunnonetsija',
    bulletPoints: [],
  },
]

export default projects
